/* raleway-regular - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	src: url("/static/fonts/raleway-v29-latin-regular.woff2") format("woff2");
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-regular - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: url("/static/fonts/roboto-v30-latin-regular.woff2") format("woff2");
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-italic - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	src: url("/static/fonts/roboto-v30-latin-italic.woff2") format("woff2");
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-700 - latin */
@font-face {
	font-display: swap;
	/* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: url("/static/fonts/roboto-v30-latin-700.woff2") format("woff2");
	/* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
