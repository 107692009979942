dl {
	display: grid;
	grid-template-columns: max-content auto;
	margin: 1rem 0;
	gap: 0.65rem;
	padding: 0.75rem 1rem;
	background: var(--color-3);
	border: 1px solid var(--color-4);
	border-radius: 0.5em;
}

dt {
	grid-column-start: 1;
	font-weight: bold;
}

dt::after {
	content: ":";
	font-weight: bold;
}

dd {
	grid-column-start: 2;
}
