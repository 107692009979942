.icongrid {
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	gap: 1em;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;
}

.icongrid li {
	display: block;
	margin: 0;
	padding: 0;

	a {
		display: block;
		position: relative;
		margin: 0;
		border-radius: 0.3em;
		cursor: pointer;
		background: #333;
		overflow: hidden;
		background-size: cover;

		&:before {
			content: '';
			float: left;
			padding-top: 100%;
		}

		img {
			position: absolute;

			top: 42%;
			left: 50%;
			width: 40%;
			height: 40%;
			transform: translate(-50%, -50%);
			object-fit: contain;
		}

		.info {
			transition: all 150ms ease 0s;

			display: flex;
			flex-direction: column;
			gap: 0.5em;
			position: absolute;
			top: calc(100% - 2.5em);
			right: 0;
			bottom: 0;
			left: 0;
			margin: 0;
			padding: 0;
			overflow: hidden;

			background: rgba(0, 0, 0, 0.6);

			font-size: 10pt;
			color: #fff;
			text-decoration: none;

			> div {
				display: block;
				margin: 0;
				padding: 0.5em;
				text-align: center;
				font-weight: bold;
			}

			p {
				margin: 0;
				padding: 0 0.5em;
			}

			.year {
				opacity: 0.6;
			}
		}
	}

	a:hover {
		box-shadow: 0 0 20px #000;
		z-index: 2;

		.info.has-desc {
			top: 0;
			background: rgba(0, 0, 0, 0.8);

			p {
				display: block;
			}
		}
	}
}


@media screen and (max-width: 800px) {
	.icongrid {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
}


@media screen and (max-width: 300px) {
	.icongrid {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}
