/*
	Header and Landing Page
*/

header h1 a {
	color: #999;
}

#landing_container {
	position: relative;
	padding: 0;/*10% 0 10% 0;*/
	margin: 0;
	overflow: hidden;
}

#landing_container, .prettybg {
	background-image: linear-gradient(to right, #06aed5, #006494);
}

#l_canvas {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background: url("canvas_bg.png?v=2");
	z-index: 30;
	margin: auto;
}

#landing {
	position: relative;
	padding: 0;/*10% 0 10% 0;*/
	margin: 0;
	vertical-align: middle;
	z-index: 40;
}

header {
	display: block;
	text-align: center;
	padding: 0;
	background: transparent;
	color: #fff;
	padding: 4em 0;

	h1 {
		margin: 0;
	}

	p {
		margin: 1.4em 0;
	}
}

.media-links {
	padding: 0 10px !important;

	img {
		width: 45px;
	}

	li {
		transition: transform 200ms ease;
	}

	li:hover {
		transform: scale(1.1, 1.1);
	}
}

@media screen and (max-width: 350px) {
	nav {
		ul {
			display: block;
		}

		li {
			display: block;

			a {
				padding: 0.75em 1em;
			}

			a:not(.selected):hover {
				font-weight: normal;
			}
		}
	}
}
