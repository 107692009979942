
nav {
	padding: 0;
	z-index: 50;

	.container {
		padding: 0;
	}

	ul, li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	ul {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
	}

	li {
		display: block;
		flex: 1 0;
	}

	li a {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		line-height: 1.5em;
		padding: 0.75rem 0.75rem;
		font-size: 120%;
		color: #f0f0f0;
		cursor: pointer;
		text-shadow: 2px 2px 2px rgba(10, 10, 10, 0.2);
	}

	li a:hover, .active {
		background: rgba(200, 200, 200, 0.2);
		color: #fff;
		text-decoration: none;
	}
}
