
.alert {
	display: block;
	background: linear-gradient(to right, #06aed5, #006494);
	color: white;
	border-radius: 0.25rem;
	padding: 1em;
	margin: 2em 0;


	h2, h3 {
		font-size: 100%;
		font-weight: bolder;
		margin: 0 0 0.3rem 0;
	}

	p {
		margin: 1em 0;
	}

	p:last-child {
		margin-bottom: 0;
	}

	a:not(.btn) {
		color: #c0eaf4;
		text-decoration: underline;

		&:hover {
			color: #b6e8f4;
		}
	}
}

.alert-danger {
	background: #E74C3C;
}
