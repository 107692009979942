figure {
	margin: 1em 0;

	figcaption {
		margin: 0.5em 0 0 0;
		color: #999 !important;
	}
}

figure.centered {
	margin: 1em auto;
	max-width: 700px;
}

img {
	max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Raleway', sans-serif;
	color: #fff;

	a {
		color: #888;
	}
}

h1 {
	font-size: 2.5rem;
}

h2 {
	font-size: 1.6rem;
}

h3 {
	font-size: 1.2rem;
	font-weight: bold;
}

h4 {
	font-size: 1rem;
	font-weight: bold;
}

.lead {
	font-size: 140%;
}

a {
	color: #06aed5;
	text-decoration: none;
}

a:hover {
	color: #06c5f2;
	text-decoration: underline;
}

p {
	line-height: 1.6em;
}

ul li {
	margin-bottom: 0.5em;
}
