.bloglist {
	list-style: none;
	margin: 1em 0;
	padding: 0;

	> li:first-child > * {
		border-top-right-radius: 0.5rem;
		border-top-left-radius: 0.5rem;
	}

	> li:last-child > * {
		border-bottom-right-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
	}

	> li {
		margin: 0;
		padding: 0;
		display: block;

		> * {
			background-color: #32383e;
			display: block;
			padding: 0;
			margin: 0 0 -1px 0;
			border: 1px solid rgba(0,0,0,0.6);
			text-decoration: none;
			color: #c8c8c8;
			transition: transform 200ms ease;
			overflow: hidden;
		}

		> .row > div:last-child {
			padding: 1em 1.2em;
		}

		> .row img {
			max-width: 20%;
			object-fit: cover;
		}

		p {
			color: #a7a7a7;
			margin: 0.75rem 0 0 0;
		}

		a:hover {
			background-color: #3e444c;
			text-decoration: none;
			transform: scale(1.01,1);
		}
	}
}
