.timeline::after {
	clear: both;
}

.timeline {
	display: block;
	margin: 0 auto;
	position: relative;

	> ul {
		position: relative;
		width: 50%;
		list-style: none;
		box-sizing: border-box;
		margin: 0;
		padding: 0;

		> li {
			position: relative;
			display: block;
			margin: 0 0;
			padding: 10px 40px;
			box-sizing: border-box;
		}
	}

	/* Container around content */
	> ul:nth-child(odd) {
		float: left;

		> li {
			padding-left: 0;
		}
	}

	/* Place the container to the right */
	> ul:nth-child(even) {
		float: right;

		> li {
			padding-right: 0;
		}
	}

	/* The circles on the timeline */
	> ul > li::after {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		right: -14px;
		background-color: var(--color-4);
		border: 4px solid var(--color-4);
		top: 15px;
		border-radius: 50%;
		z-index: 1;
	}

	> ul > li:before {
		content: " ";
		height: 0;
		position: absolute;
		top: 22px;
		width: 0;
		z-index: 1;
		border: medium solid var(--color-3);
	}

	/* Add arrows to the left container (pointing right) */
	> ul:nth-child(odd) > li::before {
		right: 30px;
		border-width: 10px 0 10px 10px;
		border-color: transparent transparent transparent var(--color-2);
	}

	/* Add arrows to the right container (pointing left) */
	> ul:nth-child(even) > li::before {
		left: 30px;
		border-width: 10px 10px 10px 0;
		border-color: transparent var(--color-2) transparent transparent;
	}

	/* Fix the circle for containers on the right side */
	> ul:nth-child(even) > li::after {
		left: -14.5px;
	}


	/* The actual content */
	> ul > li div {
		padding: 10px 14px;
		background-color: var(--color-2);
		position: relative;
		border-radius: 6px;
		overflow: hidden;

		span.dates {
			position: absolute;
			top: 0;
			right: 0;
			padding: 12px;
			color: #aaa;
		}

		h3 {
			margin: 0 0 0.5em 0;
			font-size: 1.1rem;
		}

		span.subtitle {
			margin: 0;
			font-size: 1rem;
			font-weight: bold;
		}

		p, li {
			margin: 0.5em 0 0 0;
			font-size: 1rem;
		}
	}

	.current div {
		background-color: var(--color-3);
	}

	> ul:nth-child(odd) > li.current::before {
		border-color: transparent transparent transparent var(--color-3);
	}

	> ul:nth-child(even) > li.current::before {
		border-color: transparent var(--color-3) transparent transparent;
	}
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
	content: '';
	position: absolute;
	width: 6px;
	background-color: var(--color-3);
	top: 17px;
	bottom: 290px;
	left: 50%;
	margin-left: -3px;
	border-radius: 10px;
}

.redacted {
	display: inline;
	background: black;
	color: black;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 1000px) {
	.timeline::after {
		display: none;
	}

	.timeline {
		padding: 0;
		position: static;

		> ul {
			width: auto;
		}

		> ul > li {
			width: auto;
			position: static;
			padding: 0.3em;
			display: block;
			left: auto;
			right: auto;

			div {
				padding: 1em;

				span.dates {
					padding: 1em;
				}
			}
		}

		> ul > li::before, > ul > li::after {
			display: none;
		}
	}
}
