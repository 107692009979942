footer {
	padding: 1em;
	text-align: center;
	color: #444;
	font-size: 0.9em;

	p {
		margin: 0 0 0.5em 0;
	}

	ul {
		list-style: none;
		display: block;
		margin: 0 0 2em 0;
		padding: 0;

		li {
			list-style: none;
			display: inline;
			padding: 0 0.5rem;
		}

		li a {
			color: #555;
		}
	}
}
