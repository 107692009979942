@import "fonts";

:root {
	--color-2: #1d1d2f;
	--color-3: #202033;
	--color-4: #212136;
}

html {
	font-size: 1.1rem;
	scroll-behavior: smooth;
}

* {
	scroll-margin-top: 1rem;
}

html, body {
	margin: 0;
	padding: 0;
	height: 100%;
	background: #11111c;
	font-family: "Roboto", sans-serif;
	color: #ccc;
}

.container {
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	max-width: 1000px;
}

@media screen and (max-width: 625px) {
	.container {
		width: auto;
		max-width: auto;
		padding: 15px;
	}
}

section {
	margin: 5em 0;
}

meter {
	width: 100%;
}

@import "dl";
@import "button";
@import "alert";
@import "content";
@import "icongrid";
@import "utils";
@import "landing";
@import "nav";
@import "footer";

@import "skills";
@import "bloglist";
@import "timeline";
