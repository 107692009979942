$margins: (0, 0.25rem, 0.5rem, 0.75rem, 1rem, 1.25rem, 2rem);

@for $i from 1 to 8 {
	.m-#{$i - 1} {
		margin: nth($margins, $i) !important;
	}

	.ml-#{$i - 1}, .mx-#{$i - 1} {
		margin-left: nth($margins, $i) !important;
	}

	.mr-#{$i - 1}, .mx-#{$i - 1} {
		margin-right: nth($margins, $i) !important;
	}

	.mb-#{$i - 1}, .my-#{$i - 1} {
		margin-bottom: nth($margins, $i) !important;
	}

	.mt-#{$i - 1}, .my-#{$i - 1} {
		margin-top: nth($margins, $i) !important;
	}

	.p-#{$i - 1} {
		padding: nth($margins, $i) !important;
	}

	.pl-#{$i - 1}, .px-#{$i - 1} {
		padding-left: nth($margins, $i) !important;
	}

	.pr-#{$i - 1}, .px-#{$i - 1} {
		padding-right: nth($margins, $i) !important;
	}

	.pb-#{$i - 1}, .py-#{$i - 1} {
		padding-bottom: nth($margins, $i) !important;
	}

	.pt-#{$i - 1}, .py-#{$i - 1} {
		padding-top: nth($margins, $i) !important;
	}

	.row-gap-#{$i - 1} {
		// --gap: nth($margins, $i);
		gap: nth($margins, $i);
	}
}

.d-none {
	display: none;
}

.float-right {
	float: right;
}

.text-muted {
	color: rgba(255, 255, 255, 0.4);
}

.text-dimmer {
	color: #999;
}

.col, .col-md {
	display: block;
	flex-grow: 1;
	flex-basis: 0;
	max-width: 100%;
}

.col-auto {
	display: block;
}

$columns: (8.33%, 16.66%, 24.99%, 33.32%, 41.66%, 48%, 58.33%, 66.66%, 75%, 83.33%, 91.66%, 100%);

@for $i from 1 to 12 {
	.col-#{$i} {
		flex-basis: nth($columns, $i) !important;
		flex-grow: 0;
	}
}

.row {
	display: flex !important;
	flex-direction: row;
	flex-wrap: wrap;
}


@media screen and (max-width: 625px) {
	.col-md {
		display: block !important;
		flex: 0 0 100% !important;
	}
}

@media screen and (min-width: 625px) {
	.row-md-reverse {
		flex-direction: row-reverse;
	}
}

.undo-ul, .undo-ul > li {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ratio-16x9 {
	display: block;
	width: 100%;
	aspect-ratio: 16 / 9;
	object-fit: cover;
}
