/*
	Skills List
*/

.skills-list {
	list-style: none;
	margin: 2em 0 0 0;
	padding: 0;
	display: flex;
	gap: 1.25em;

	& > li {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		flex: 1;

		& > h3 {
			margin: 0 0 1em 0;
			font-size: 100%;
			font-weight: bold;
			color: #999;
		}

		& > ul {
			margin: 0;
			padding-left: 1em;
			list-style: square;
		}
	}
}

@media screen and (max-width: 770px) {
	main {
		width: auto;
		max-width: none;
	}
}

@media screen and (max-width: 650px) {
	.skills-list, .skills-list > li {
		display: block;
	}


	.skills-list > li {
		margin-top: 1em;
	}
}
